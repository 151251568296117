/* Carrito.css */

.cart-container {
  width: calc(100vw - 40%);
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  
  z-index: 999;
}

/* Style for the sidebar container */
.carrito-sidebar {
  position: fixed;
  top: 59px;
  right: -30%; /* Start closed by default */
  height: calc(100vh - 59px);
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: right 0.3s ease-in-out; /* Animate right property for transition */
}

/* Estilizar la barra de desplazamiento */
.carrito-container::-webkit-scrollbar {
  width: 6px; /* Ancho de la barra de desplazamiento */
}

/* Estilizar el riel de la barra de desplazamiento */
.carrito-container::-webkit-scrollbar-track {
  background: transparent; /* Fondo transparente */
}

/* Estilizar el mango de la barra de desplazamiento */
.carrito-container::-webkit-scrollbar-thumb {
  background-color: #de2424; /* Color del mango */
  border-radius: 6px; /* Borde redondeado */
  border: 3px solid transparent; /* Borde transparente */
}

/* Estilizar el mango de la barra de desplazamiento cuando está en estado hover */
.carrito-container::-webkit-scrollbar-thumb:hover {
  background-color: #ad1f1f; /* Color del mango al pasar el mouse */
}

/* Style for the open sidebar */
.carrito-sidebar.open {
  right: 0; /* Open the sidebar */
  width: 95%;
  background-color: #e8e8e8;
}


.toggleCart {
  position: fixed;
  background-color: none;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
/* Style for the close button (X) */
.carrito-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1rem;
  cursor: pointer;
  color: #ffffff;
  z-index: 9;
  background-color: #122243;
  border-radius: 50%;
  padding: 1px 7px;
}

.carrito-close-icon {
  color: #ffffff;
}

/* Style for the container inside the sidebar */
.carrito-container {
  background-color: #e8e8e8;
  padding-top: 0;
  height: calc(100vh - 170px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.empty-cart {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 0 40px;
  padding-top: 70px;
}

.image img {
  max-width: 150px
}
/* Style for the title of the cart */
.carrito-title {
  font-size: 1.2rem;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  width: 100%;
  text-align: center;
}

.cantidad {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.cantidad input {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #fff;
  border: none;
}

.none {
  display: none;
}

.carrito-items {
  background-color: #fff;
  padding: 15px;
  padding-top: 0;
}

/* Style for each item in the cart */
.carrito-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  max-width: 100%;
  position: relative;
  border-bottom: 0.5px solid #a9a9a9;
  padding: 5px 0;
}

.carrito-item .texto {
  display: flex;
  flex-direction: column;
  text-align: justify;
  justify-content: space-between;
  margin-left: 8px;
}
.carrito-item .texto h4 {
  font-size: 1rem;
}
.carrito-item .texto p {
  font-size: 0.8rem;
}
.opciones-menu {
  display: flex;
  flex-direction: column;
}

.opciones-menu select {
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
}

.carrito-button-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.opciones-carrito {
  display: flex;
  flex-direction: column;
}

.opciones-carrito a {
  color: white;
  text-decoration: none;
  display: flex;
}

.carrito-options-fixed {
  position: fixed;
  background-color: white;
  width: 95%;
  bottom: 0;
  right: 0;
  padding: 0 20px 10px;
  border-top: 2px solid #122243;
}

/* Style for the "Realizar Pedido" button */
.carrito-button {
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  color: #fff;
  background-color: #122243;
}
.carrito-button:hover {
  background-color: #122243;
}

/* Style for the quantity text */
.carrito-quantity {
  font-size: 1rem;
  font-weight: bold;
}

.carrito-img {
  max-height: 80px;
  max-width: 80px;
  border-radius: 15px;
  min-height: 80px;
  min-width: 80px;
}


.enviar-comprobante {
  color: #1fb355;
  font-size: 2.2rem;
  background-color: #1fb35569;
  text-align: center;
  padding: 6px;
  border-radius: 10px;
}

.seguir-comprando,
.carrito-button-pedido {
  width: 100%;
  border-radius: 20px;
  font-weight: bold;
}

.carrito-button-pedido {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
}

.seguir-comprando {
  background-color: white;
  color: #122243;
  border: 2px solid #122243;
}

.image-product-price {
  display: flex;
  flex: 2;
}

.trash {
  font-size: 1.15rem;
  color: #de2424;
  display: flex;
  align-self: flex-end;
  margin-bottom: 10px;
}

.product-price {
  font-size: 0.9rem;
  flex: 2;
  text-align: left;
  padding: 0 0 0 6px;
}

.product-price ul{
  font-size: 0.7rem;
}

.product-price li{
  text-align: left;
}

.quantity-trash {
  display: flex;
  flex-direction: column;
}

.quantity-cell {
  display: flex;
  flex: 2;
  line-height: 15px;
}

.quantity-button {
  padding: 3px;
  padding-left: 4px;
  border-radius: 5px;
  font-size: 1.8rem;
}

.item-quantity {
  width: 30px;
  text-align: center;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
}

.subtotal {
  font-size: 0.95rem;
  padding: 0 5px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.section__carousel-recommended {
  height: 320px;
}

.section__carousel-recommended h3 {
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}

.cantidad-precio {
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 10px;
}

.carousel-product {
  text-align: center;
}

.carousel-product img {
  display: flex;
  margin: 0 auto;
}

/* Aplica estilos a los puntos (dots) del carrusel */
.custom-slider .slick-dots {
  /* Estilos personalizados */
  margin: 20px;
}

.instructions-list {
  padding: 10px ;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.instructions-list li {
  font-size: 1rem;
  text-align: left;
}

.instructions-list h2 {
  text-align: center;
  font-size: 1.3rem;
}



.instructions-list .circle {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  margin-right: 10px;
}



@media screen and (max-width: 470px) {
  .carrito-sidebar.open {
    width: 95%;
  }
}


@media screen and (min-width: 470px) {
  .carrito-sidebar.open {
    width: 85%;
  }
}
@media screen and (min-width: 535px) {
  .carrito-sidebar.open {
    width: 80%;
  }
}

@media screen and (min-width: 600px) {
  .carrito-sidebar.open {
    width: 75%;
  }
}

@media screen and (min-width: 700px) {
  .carrito-options-fixed {
    width: 60%;
  }
}

@media screen and (min-width: 800px) {
  .carrito-sidebar.open, .carrito-options-fixed {
    width: 60vw;
  }
}

@media screen and (min-width: 900px) {
  .carrito-sidebar.open, .carrito-options-fixed {
    width: 50vw;
  }
}
@media screen and (min-width: 1000px) {
  .carrito-sidebar.open, .carrito-options-fixed {
    width: 40vw;
  }
}
@media screen and (min-width: 1000px) {
  .carrito-sidebar.open, .carrito-options-fixed {
    width: 40vw;
  }
}

@media screen and (min-width: 1200px) {
  .carrito-sidebar.open, .carrito-options-fixed {
    width: 35vw;
  }
}

@media screen and (min-width: 1500px) {
  .carrito-sidebar.open, .carrito-options-fixed {
    width: 25vw;
  }
}