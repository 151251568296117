body {
  margin: 0;
  /* background-color: rgba(191, 191, 191, 0.895); */

  font-family: "Poetsen One", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.countdown-timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  height: calc(100vh - 61px - 61px);
  z-index: 4000;
}

.oferta {
  font-size: 1.1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #000;
  text-align: center;
  line-height: 40px;
}

.banner {
  background-color: #000;
  color: #fff;
}

.countdown-timer span {
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 0.5rem;
}

.countdown-timer .icon {
  font-size: 3rem;
  color: #446688;
  margin-right: 1rem;
}

.countdown-timer .message {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 1rem;
}

.countdown-timer .cta {
  display: inline-block;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.2rem;
  margin-top: 1rem;
  font-weight: bold;
  transition: background-color 0.2s ease;
  width: 100%;
  background-color: #0f385e;
}

.agregar-container .quantity-button {
  background-color: #002b44;
  color: #fff;
}

a {
  text-decoration: none;
}

.countdown-timer .cta:hover {
  background-color: #334d66;
}

.clock-icon:before {
  content: "\f017"; /* FontAwesome clock icon */
}

.shopping-cart-icon:before {
  content: "\f07a"; /* FontAwesome shopping cart icon */
}

.time {
  background-color: #ffcc00; /* Color de fondo */
  color: #000; /* Color del texto */
  border-radius: 5px; /* Bordes redondeados */
  font-size: 18px; /* Tamaño de fuente */
  font-weight: bold; /* Negrita */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra */
  transition: box-shadow 0.3s ease; /* Transición de sombra */
  height: 40px;
  width: 100%;
}

.time:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra más grande al pasar el mouse */
}

.time p {
  font-size: 1.2rem;
  line-height: 40px;
  text-align: center;
}

.time span {
  font-size: 1.1rem;
  font-weight: bold;
  margin-right: 0.5rem;
}

.flex-space-around {
  display: flex;
  justify-content: space-around;
}
.pd-20 {
  padding: 20px;
}

.pd-bt-0 {
  padding-bottom: 0;
}
