@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
/* Estilo para el encabezado */
/* Estilos para el header-container */
/* Estilo inicial de la barra de navegación */
.header-container {
  background: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 1000;
  margin-bottom: 20px;
  width: 100%;
  height: 60px;
  top: 0;
  font-family: "Poppins", sans-serif;
  transition: background-color 0.3s ease; /* Agrega una transición suave */
}

.login-logout {
  margin: 0;
  font-size: 0.74em;
  border-radius: 15px;
  font-weight: bold;
  background-color: #fff;
  color: #000;
}

/* Estilo para la barra de navegación */
nav {
  text-align: center; /* Centra los elementos de navegación horizontalmente */
  width: 100%;
}

.links-principales {
  font-size: 1.2rem;
  margin: 10px;
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
}

.header a, .pide-online a, .nombre-usuario {
  -webkit-user-select: none;
  user-select: none;
}

/* Estilo para la lista no ordenada (ul) */
ul {
  list-style: none; /* Quita los puntos de lista predeterminados */
  padding: 0; /* Elimina el espacio de relleno predeterminado */
  margin: 0; /* Elimina el margen predeterminado */
}

.header-elements {
  margin: 0 auto;
}

.banner-img {
  max-height: 65px;
}

.nombre-usuario {
  margin-right: 10px;
  color: #fff;
}
.nombre-usuario:hover {
  cursor: pointer;
}

/* Estilo para los elementos de la lista (li) */
li {
  margin-right: 20px; /* Agrega espacio horizontal entre los elementos */
}

/* Estilo para los enlaces (a) */
li a {
  text-decoration: none; /* Quita la subrayado de los enlaces */
  color: #000;
  font-weight: bold; /* Establece el peso de la fuente en negrita */
  font-size: 16px; /* Tamaño de la fuente para los enlaces */
}

.icon {
  color: #fff;
}

/* Estilos para la lista no ordenada (ul) */
.header-ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex; /* Cambia el comportamiento de la lista a una disposición flexible */
  justify-content: space-between; /* Distribuye los elementos a lo largo del contenedor */
  align-items: center; /* Centra verticalmente los elementos */
  width: 100%; /* Ocupa todo el ancho disponible */
}

/* Estilo para los elementos de la lista (li) */
li {
  margin: 0;
  padding: 0;
  text-align: center;
}

.cart-div {
  border-radius: 6px;
  margin-left: 10px ;
  background-color: #fff;
}


/* ListaProductos.css */
  
  /* Estilo para los botones */
  .cart-div  {
    color: #000; /* Letra blanca */
    border: none;
    padding: 5px 10px;
    font-weight: bold;
    cursor: pointer;
    font-size: 1.02rem;
  }

  .cart-div:hover  {
    font-size: 1.04rem;
  }

  .user-menu {
    top: 60px;
    left: 50%;
    width: 80%;
    background: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    position: fixed;
    overflow-y: auto;
    z-index: 4000;
    margin: 0 auto;
    transform: translateX(-50%);
  }
  
  .userName {
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
  }

  .flex-11 {
    display: flex;
  }

@media screen and (max-width: 772px) {
  .user-menu {
    width: 70%;
  }
}

@media screen and (max-width: 425px) {
  .user-menu {
    width: 100%;
  }
}

@media screen and (min-width: 772px) {
  .user-menu {
    width: 40%;
  }
}
.user-menu ul {
  /* Agrega estilos a los elementos dentro del menú */
  padding: 10px; /* Añade espacio entre elementos (ajusta según tus necesidades) */
  text-align: center;
  display: flex;
  flex-direction: column; /* Cambia la disposición a vertical */
}
.pide-online {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 20px;
  padding: 5px 10px;
  border-radius: 20px;
  transition: box-shadow 0.3s, transform 0.3s; /* Agregamos transiciones para suavizar el cambio */
}

.pide-online a {
  color: #000;
  text-decoration: none;
}

.sidebarLink {
  color: white;
}
.pide-online:hover {
  background-color: rgb(15, 66, 111);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Agregamos un resplandor utilizando box-shadow */
  transform: scale(
    1.1
  ); /* Hacemos que el botón se agrande al 110% del tamaño original */
}
.pide-online a:hover {
  text-decoration: none;
}


/* Agrega estas reglas a tu archivo Header.css */


.sidebar-toggle {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
}

.sidebar-content.open {
  transform: translateX(0);
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  padding: 10px;
}

.sidebar ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}

.header-ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-ul li {
  margin-right: 20px;
}


.sidebar-content {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  margin-top: 60px;
  height: calc(100vh - 72px);
}

.toggleSidebar {
  width: 20vw;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
}

.sidebar-content ul {
  background-color: #000;
  width: 80vw;
  list-style-type: none;
  padding: 0;
  height: calc(100vh);
}

.sidebar-content li {
  padding: 10px;
  transition: background-color 0.3s ease;
  border-bottom: solid 2px #ffffff4e;
}

.sidebar-content li:hover {
  background-color: #444; /* Color de fondo cuando se pasa el cursor */
}

.links-principales {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.links-principales:hover {
  color: #fff;
}

.links-principales svg {
  margin-right: 10px;
}


.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  padding: 10px;
}

.sidebar ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}


/* styles.css */

.user-circle {
  list-style-type: none;
  cursor: pointer;
}

.circle-container {
  width: 30px; /* Diámetro del círculo */
  height: 30px; /* Diámetro del círculo */
  background-color: #ffffffa5; /* Color del círculo */
  border-radius: 50%; /* Forma el contenedor en un círculo */
  display: flex;
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
}

.user-icon {
  color: #fff; /* Color del icono */
  font-size: 15px; /* Tamaño del icono */
}

.sidebar-content li {
  padding: 10px;
  transition: background-color 0.3s ease;
  border-bottom: solid 2px #ffffff4e;
}

.sidebar-content li:hover {
  background-color: #444; /* Color de fondo cuando se pasa el cursor */
}


.links-principales svg {
  margin-right: 10px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  padding: 10px;
}

.sidebar ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}

.header-btn {
  background-color: #fff;
  color: #000;
  margin-right: 15px;
  font-weight: bold;
  border-radius: 15px;
}