
/* Estilo para el encabezado */
/* Estilos para el footer-container */
/* Estilo inicial de la barra de navegación */
.footer-container {
  background: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 60px;
  bottom: 0;
  transition: background-color 0.3s ease; /* Agrega una transición suave */
}

.escribenos {
  font-size: 1rem;
}
.wa {
  color: #fff;
  font-size: 2rem;
}

/* Estilo para la barra de navegación */
nav {
  text-align: center; /* Centra los elementos de navegación horizontalmente */
  width: 100%;
}

.links-principales {
  margin: 10px;
  -webkit-user-select: none;
  user-select: none;
}

.footer a, .pide-online a, .nombre-usuario {
  -webkit-user-select: none;
  user-select: none;
}

/* Estilo para la lista no ordenada (ul) */
ul {
  list-style: none; /* Quita los puntos de lista predeterminados */
  padding: 0; /* Elimina el espacio de relleno predeterminado */
  margin: 0; /* Elimina el margen predeterminado */
}

.footer-elements {
  margin: 0 auto;
}

.banner-img {
  max-height: 55px;
}

.nombre-usuario {
  margin-right: 10px;
  color: #fff;
}
.nombre-usuario:hover {
  cursor: pointer;
}

.icon {
  color: #ff6265;
}

/* Estilos para la lista no ordenada (ul) */
.footer-ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex; /* Cambia el comportamiento de la lista a una disposición flexible */
  justify-content: space-around; /* Distribuye los elementos a lo largo del contenedor */
  align-items: center; /* Centra verticalmente los elementos */
  width: 100%; /* Ocupa todo el ancho disponible */
}

/* Estilo para los elementos de la lista (li) */
li {
  margin: 0;
  padding: 0;
  text-align: center;
}

.cart-div {
  border-radius: 6px;
  margin-left: 10px ;
  background-color: #fff;
}


/* ListaProductos.css */
  
  /* Estilo para los botones */
  .cart-div  {
    color: #000; /* Letra blanca */
    border: none;
    padding: 5px 10px;
    font-weight: bold;
    cursor: pointer;
    font-size: 1.02rem;
  }

  .cart-div:hover  {
    font-size: 1.04rem;
  }

  .user-menu {
    top: 60px;
    left: 50%;
    width: 80%;
    background: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    position: fixed;
    overflow-y: auto;
    z-index: 4000;
    margin: 0 auto;
    transform: translateX(-50%);
  }
  

@media screen and (max-width: 772px) {
  .user-menu {
    width: 70%;
  }
}

@media screen and (max-width: 425px) {
  .user-menu {
    width: 100%;
  }
}

@media screen and (min-width: 772px) {
  .user-menu {
    width: 40%;
  }
}
.user-menu ul {
  /* Agrega estilos a los elementos dentro del menú */
  padding: 10px; /* Añade espacio entre elementos (ajusta según tus necesidades) */
  text-align: center;
  display: flex;
  flex-direction: column; /* Cambia la disposición a vertical */
}
.pide-online {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 20px;
  padding: 5px 10px;
  border-radius: 20px;
  transition: box-shadow 0.3s, transform 0.3s; /* Agregamos transiciones para suavizar el cambio */
}

.pide-online a {
  color: #fff;
  text-decoration: none;
}

.pide-online:hover {
  background-color: rgb(15, 66, 111);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Agregamos un resplandor utilizando box-shadow */
  transform: scale(
    1.1
  ); /* Hacemos que el botón se agrande al 110% del tamaño original */
}
.pide-online a:hover {
  text-decoration: none;
}

.payment_methods {
  color: #fff;
  gap: 5px;
}

.payment_methods a{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.method {
  font-size: 22px;
}