.product-table {
  border-radius: 0;
}

@media screen and (min-width: 800px) {
  .product-table {
    width: 70%;
    margin: 20px auto;
  }
}

.product-table thead,
.product-table tbody,
.cart-subtotal,
.cart-shipping,
.cart-total {
  border: 1px solid #d5d5d5;
}

.cart-subtotal td,
.cart-shipping td,
.cart-total td {
  padding: 15px;
}

.product-table th {
  background: none;
  color: black;
  border: none;
  font-size: 14px;
  padding: 10px;
}

.product-name {
  text-align: left;
  top: 0;
}
.product-table td {
  background: none;
  color: black;
  border: none;
  font-size: 14px;
  text-align: justify;
  padding: 10px;
}

.product-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; /* Espacio entre elementos product-details */
}

.product-details p {
  margin: 0; /* Eliminar el margen predeterminado de los párrafos */
}

.red-text {
  color: #f00;
}

.buttons {
  text-align: center;
}

.details-name {
  flex: 2;
}

.details {
  overflow-x: auto;
}

@media (min-width: 768px) {
  .product-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Espacio entre elementos product-details */
  }

  .product-details p {
    margin: 0; /* Eliminar el margen predeterminado de los párrafos */
  }

  .details-name {
    flex: 2;
  }

  /* Estilos para el elemento .details cuando se hace sticky */
  .details.sticky {
    bottom: 0; /* Lo anclamos en la parte inferior del contenedor principal */
    background-color: #fff; /* Cambia el fondo según tus preferencias */
    z-index: 1; /* Asegúrate de que esté por encima de otros elementos */
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.2); /* Opcional: agrega una sombra en la parte inferior */
  }
  .details {
    top: 0;
    position: relative;
  }
}
