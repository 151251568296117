/* CheckoutShipping.css */

.heading {
  font-size: 1.4rem;
  text-align: justify;
  padding: 10px 0;
}

.confirmar-pedido {
  padding: 20px;
  width: 100%;
  height: calc(100vh - 61px);
}
.select-pago {
  text-align: center;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f8f8;
  appearance: none; /* Eliminar el estilo por defecto del select en iOS */
}

.checkout-options {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}

.error-message {
  background-color: #ffcccc;
  color: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

/* Estilos para dispositivos móviles */
@media screen and (max-width: 768px) {
  .checkout-options {
    text-align: center;
  }

  .select-pago {
    width: 80%;
    margin: 0 auto;
  }
}

.shipping-details {
  text-align: justify;
  padding: 0 20px;
}

.flex {
  display: flex;
  align-items: left;
  align-self: center;
  flex: 1;
}

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}

#nombre-container {
  margin-right: 8px;
  flex: 1.4;
  width: 100%;
}

#apellidos-container {
  flex: 2;
  width: 100%;
}

.ver-pedido {
  font-size: 1.4rem;
  -webkit-user-select: none;
  user-select: none;
  margin-right: 10px;
}
.ver-pedido:hover {
  cursor: pointer;
}

.continue-shopping {
  color: #122243;
  font-weight: bold;
  font-size: 2.4rem;
  text-align: center;
  background-color: #1222438c;
  padding: 2px;
  border-radius: 10px;
}

.continue-shopping:hover {
  cursor: pointer;
}

.flex-form-input label,
.input-group {
  margin-right: 10px;
  margin-bottom: 8px;
  color: #000;
  font-size: 14px;
}

.color-red-bold {
  color: red;
  font-weight: bold;
}

.color-red {
  color: red;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  flex: 1;
  margin-bottom: 15px;
}

.flex-space-between {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
}

.flex-space-between:hover {
  cursor: pointer;
  color: #3c3c3c;
}

.content-box {
  margin: 10px auto;
}

.ver-pedido {
  padding: 20px;
}

.checkout-form-container {
  width: 100%;
  align-items: center;
  text-align: justify;
  overflow-y: auto;
  padding: 10px;
}

.form {
  border-collapse: collapse;
  justify-content: center;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  flex: 1;
}

.input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
}

.submit-button {
  background-color: #122243; /* Color de fondo */
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}

.flex-buttons {
  display: flex;
  justify-content: space-between;
  margin: 15px auto;
}

.submit-button {
  border: none; /* Sin borde */
  color: white; /* Color del texto */
  padding: 15px 32px; /* Espaciado interno */
  text-align: center; /* Alineación del texto */
  text-decoration: none; /* Sin subrayado */
  cursor: pointer; /* Cursor al pasar */
  transition: background-color 0.3s ease; /* Transición de color de fondo */
}

.submit-button:hover {
  background-color: #45a049; /* Color de fondo al pasar el mouse */
}

.rotate {
  transform: rotate(45deg);
  transition: transform 0.3s ease; /* Agrega una transición suave */
}

.close-div {
  background: none;
}
.close-div:hover {
  background: none;
}

.close-icon {
  font-size: 24px;
  color: black;
}
.close-icon:hover {
  color: rgb(136, 136, 136);
}

.rotate.reverse {
  transform: rotate(0deg);
}

.submit-button:hover {
  background-color: #9adaff; /* Cambia el color al pasar el mouse */
}

.form {
  width: 100%;

  margin: 0;
}

.title {
  text-align: center;
  font-size: 1.5rem;
}

.tarjeta-debito-credito {
  margin-top: 20px;
}

.tarjeta-debito-credito label {
  display: block;
  margin-bottom: 8px;
}

.tarjeta-debito-credito input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.next-step-button {
  background-color: #122243;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  display: flex;
}

.next-step-button:hover {
  background-color: #f00; /* Change color on hover */
}

/* Media query for responsive design */
@media (min-width: 768px) {
  .row {
    flex-direction: row;
  }

  .flex-form-input {
    display: flex;
    flex-direction: column;
    align-items: justify;
    flex: 1;
  }

  .next-step-button {
    right: 0;
  }

  .input {
    margin-bottom: 10px;
  }

  .submit-button {
    margin-top: 10px;
  }
  .item-container {
    height: auto; /* Adjust height for responsive design */
  }

  .checkout-form-container {
    flex-direction: row;
    width: 95%;
    margin: 0 auto;
    max-height: calc(
      100vh - 61px - 61px - 61px
    ); /* Set a maximum height for the table container */
    position: relative; /* Add this to establish a stacking context */
  }

  .form {
    width: 100%;

    margin: 0;
  }
}

.login-section {
  text-align: left;
  margin-bottom: 20px;
}

.cambiar,
.login-section a {
  color: #4169e1;
  text-decoration: none;
}

.omitir,
.cambiar {
  font-size: 12px; /* O ajusta el tamaño según tus preferencias */
  color: rgb(81, 81, 81); /* Cambia el color según tus preferencias */
  margin-left: 15px;
  /* Otros estilos que desees agregar */
}
.omitir:hover {
  cursor: pointer;
  color: rgb(200, 200, 200); /* Cambia el color según tus preferencias */
  /* Otros estilos que desees agregar */
}

.email-de-usuario {
  font-size: 1rem;
  padding: 10px;
}

.login-section a,
.cambiar {
  color: royalblue;
  text-decoration: none;
}
.login-section a:hover,
.cambiar:hover {
  cursor: pointer;
  color: rgb(29, 47, 100);
  text-decoration: none;
}

.pagar-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}
/* Estilos generales para la pasarela de pago */
.tarjeta-debito-credito {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Estilos para etiquetas y campos de entrada */
.tarjeta-debito-credito label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.tarjeta-debito-credito input {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Estilo para el botón de enviar */
.tarjeta-debito-credito button {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.tarjeta-debito-credito button:hover {
  background-color: #45a049;
}

.payment-options {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.checkout-payment-options {
  padding: 3px;
  display: flex;
  justify-content: space-around;
}

.payment-box {
  display: flex;
  justify-content: center;
}

.flex-order-1 {
  flex: 1;
}

.flex-order-2 {
  flex: 1;
  order: -1;
}

@media screen and (max-width: 915px) {
  .payment-box {
    flex-direction: column;
  }

  .flex-order-1 {
    flex: 1;
    order: -1;
  }

  .flex-order-2 {
    flex: 1;
  }
  .shipping-details,
  .heading {
    text-align: left;
  }
}

.shipping-info {
  margin: 10px auto;
  font-size: 0.8rem;
}

.shipping-info-item {
  margin-bottom: 10px;
}

.info-label {
  font-weight: bold;
}

.checkout-option {
  padding: 10px 10px 5px 10px;
  color: #122243;
  border-bottom: solid 3px #122243;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkout-option:hover {
  background-color: #0056b3;
}

.confirmar-pedido {
  width: 100%;
  margin: 0 auto;
  margin-top: 61px;
  padding-bottom: 61px;
  overflow-y: scroll;
}

.cargando {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cargando-text {
  z-index: 999;
  font-family: "Poetsen One", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #df2424;
}

.intercalado {
  margin-right: 10px;
  animation: intercalado 1s linear infinite;
}

.spinner {
  animation: spin 1s linear infinite;
  font-family: "Poetsen One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes intercalado {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.select-pago {
  text-align: center;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f8f8;
  appearance: none; /* Eliminar el estilo por defecto del select en iOS */
}

/* Estilos para dispositivos móviles */
@media screen and (max-width: 768px) {
  .checkout-options {
    text-align: center;
  }

  .select-pago {
    width: 80%;
    margin: 0 auto;
  }
}

.aviso-importante2 {
  background-color: #b3ffbd; /* Color de fondo amarillo claro */
  border: 2px solid #30fb2d; /* Borde amarillo */
  padding: 10px; /* Espaciado interno */
  border-radius: 5px; /* Bordes redondeados */
  font-weight: bold; /* Texto en negrita */
  margin-bottom: 10px;
}

.aviso-importante {
  background-color: #ffecb3; /* Color de fondo amarillo claro */
  border: 2px solid #fbc02d; /* Borde amarillo */
  padding: 10px; /* Espaciado interno */
  border-radius: 5px; /* Bordes redondeados */
  font-weight: bold; /* Texto en negrita */
  font-size: 0.7rem;
}

.shipping-details {
  text-align: justify;
  padding: 0 20px;
}

.flex {
  display: flex;
  align-items: left;
  align-self: center;
  flex: 1;
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}

.ver-pedido {
  font-size: 1.4rem;
  -webkit-user-select: none;
  user-select: none;
  margin-right: 10px;
}
.ver-pedido:hover {
  cursor: pointer;
}

.continue-shopping {
  color: #e33534;
  font-weight: bold;
  font-size: 1.6rem;
  text-align: center;
  background-color: #e3353442;
  padding: 2px;
  border-radius: 10px;
}

.continue-shopping:hover {
  cursor: pointer;
}

.flex-form-input label,
.input-group {
  margin-right: 10px;
  margin-bottom: 8px;
  color: #000;
  font-size: 14px;
}

.color-red-bold {
  color: red;
  font-weight: bold;
}

.color-red {
  color: red;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  flex: 1;
  margin-bottom: 15px;
}

.ver-pedido {
  padding: 20px;
}

.checkout-form-container {
  text-align: justify;
  width: 100%;
  padding-bottom: 60px;
}

.form {
  border-collapse: collapse;
  justify-content: center;
}
