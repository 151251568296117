/* MisPedidos.css */

.mis-pedidos-container {
  background: #d0d0d0;
  padding: 20px 50px;
}

@media screen and (max-width: 650px) {
  .mis-pedidos-container {
    padding: 20px 10px;
  }
}
.section-title {
  background-color: #000000ed;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
}

.green-bg {
  background-color: green !important;
}

.section-title h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.section-title h2 {
  font-size: 1.5rem;
  margin-top: 10px;
}

.section-title p {
  font-size: 1rem;
  margin-top: 10px;
}

.mg-r-10 {
  margin-right: 40px;
}
.section-title div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-title button {
  padding: 10px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
.detalles-card-container {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  background: #000000bb;
}

.detalles-card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 15px 25px;
  z-index: 3000;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  min-width: 320px;
  max-height: 80%; /* Set the maximum height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  border-radius: 20px;
}

.update {
  background-color: #e74c3c;

}

.nuevo-pedido {
  background-color: #2ecc71;

}

/* Add a close button to the popup */
.detalles-card-close-btn {
  /* Add styling for the close button */
  background: none;
  border: none;
  color: rgb(0, 0, 0);
  font-size: 20px; /* Adjust the font size as needed */
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1100; /* Set a higher z-index to ensure it appears above the card */
}

.detalles-card li {
  text-align: justify;
}

.section-title button.red-background {
  background-color: #e74c3c;
}

.section-title button.black-background {
  background-color: #2c3e50;
}

.section-title label {
  margin-left: 10px;
}

.section-title select {
  padding: 8px;
  font-size: 1rem;
}

.pedido-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.pedido-card {
  list-style: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.pedido-card:hover {
  transform: scale(1.05);
}

.pedido-preview {
  padding: 16px;
}

.pedido-preview li {
  margin-bottom: 10px;
}

#status-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
#status-circle-2 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 10px;
}

#status-circle-2 {
  background-color: #8cf8b9;
 
}

.activo {
  background-color: #ffffff;
  transform: scale(0.6);
}

@keyframes pulsar {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}

@keyframes pulsar2 {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(0.7);
  }
}

.en-camino {
  background-color: #2ecc71;
  
}

.animar {
  animation: pulsar 2s infinite; /* 2s es la duración del ciclo, puedes ajustarla según tus necesidades */
}

.animar2 {
  animation: pulsar2 2s infinite; /* 2s es la duración del ciclo, puedes ajustarla según tus necesidades */
}

.finalizado {
  background-color: #3498db;
  
  transform: scale(0.7);
}

.selected {
  border: 2px solid #000;
}

.detalle-mi-pedido {
  margin-top: 10px;
}



.costo-envio-total {
  text-align: right;
}

.red {
  color: red;
}

.resumen-detalles {
  margin-top: 20px;
}

.nuevo-pedido {
  background-color: #2ecc71;
}

.pedido-details,
.transaccion-details {
  text-align: left;
}

.mis-pedidos-actions {
  display: flex;
}

.mis-pedidos-actions button {
  margin-right: 10px;
}

.soporte {
  width: 40px; /* Ajusta el tamaño del círculo según tus necesidades */
  height: 40px;
  background-color: #3498db; /* Color de fondo del círculo */
  border-radius: 50%; /* Hace que el elemento tenga forma de círculo */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px; /* Ajusta el espacio entre el círculo y los otros elementos */
  color: #fff; /* Ajusta el color del ícono */
  cursor: pointer; /* Ajusta el cursor para indicar que es interactivo */
}

.soporte div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Asegura que el contenido se ajuste correctamente */
  height: 100%; /* Asegura que el contenido se ajuste correctamente */
}

.soporte div a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Asegura que el contenido se ajuste correctamente */
  height: 100%; /* Asegura que el contenido se ajuste correctamente */
  color: #fff; /* Ajusta el color del ícono */
}
.headset-icon {
  font-size: 1.3rem;
}