/* Menu.css */
* {
  margin: 0;
}

.store-container {
  margin-top: 59px;
  height: calc(100vh - 59px - 59px);
  background: #000;
  margin-bottom: 59px;
}
/* Estilo para el contenedor principal del menú */
.menu-container {
  background-color: #000000;
  padding: 10px;
  display: flex;
  padding-bottom: 0;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  gap: 10px;
  justify-content: center;

  align-items: center; /* Centra verticalmente los elementos en el contenedor */
}

.menu-item-content {
  display: flex;
  flex-direction: row;
  min-height: 150px;
  justify-content: space-between;
}

.menu-item-content .description {
  padding: 8px;
}

.agregar-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra horizontalmente el botón */
}

.agregar-button {
  width: 100%;
  border: none;
  font-size: 0.8rem;
  cursor: pointer;
  background-color: #002b44;
  color: #ccc;
  font-weight: bold;
  font-size: 1.5rem;
}

.agregar-button:hover {
  background-color: #002b44; /* Cambia el color al pasar el cursor */
}

.ver-carrito-container {
  display: flex;
  text-align: center;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 20px;
  padding-top: 10px;
}

.ver-carrito {
  text-align: center;
  width: 150px;
  margin: 0 auto;
  border-radius: 5px;
  font-size: 18px;
}

.estado {
  font-size: 18px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  width: 220px;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
  align-self: center;
  padding: 4px;
}
.estado a {
  text-decoration: none;
  color: #fff;
}

.categoria-filtros {
  padding: 2vh 2vw 0 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%; /* Ajusta al ancho completo de la pantalla */
  max-width: 600px; /* Limita el ancho máximo */
  margin: 0 auto; /* Centra horizontalmente */
  flex-wrap: wrap;
}

.categoria-activa {
  /* Estilos adicionales para los botones */
  background-color: #a43efe;
}

.quantity-buttons {
  display: flex;
  background: #122243;
  width: 100%;
}

/* Estilo para el título principal */
.menu-category h2 {
  font-size: 1.2rem; /* Tamaño de fuente */
  border-bottom: 2px solid #002b44; /* Borde amarillo debajo del título */
  padding-bottom: 5px; /* Espacio entre el borde y el texto */
  color: #ffffff; /* Color del texto */
}

.menu-options {
  background-color: #000;
  padding: 1vh 2vw;
  max-width: 800px;
  margin: 0 auto;
}
.menu-list {
  display: flex;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* Uso de CSS Grid para el diseño responsive */
  gap: 10px;
}

/* Estilo para cada elemento del menú */
.menu-item {
  overflow: hidden;
  margin: 0;
  max-width: 350px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex; /* Utiliza flexbox para controlar el diseño */
  flex-direction: column; /* Cambia a columna para colocar elementos uno debajo del otro */
}

/* Estilo para la imagen con borde redondeado */
.menu-item img {
  height: 120px; /* Mantiene la proporción original de la imagen */
}

/* Estilo para el título del elemento del menú */
.menu-item h3 {
  font-size: 0.9rem;
  font-weight: bold;
  padding: 0 5px;
}

/* Estilo para la descripción del elemento del menú */
.menu-item p {
  font-size: 0.8rem;
  padding: 5px;
}

/* Estilo para el precio del elemento del menú */
.menu-item .price {
  text-align: left;
  font-size: 0.9rem;
  margin: 0;
  color: #000;
  font-weight: bold;
}

.menu-item .price-real {
  text-align: left;
  margin: 0;
  font-size: 0.7rem;
  color: #262626;
  font-weight: bold;
}

/* Estilo para dispositivos móviles (ejemplo: ancho máximo de 600px) */
@media (max-width: 600px) {
}

.red-text {
  color: #f00;
}

/* Estilos para dispositivos móviles con ancho igual o menor a 540px */
@media (max-width: 840px) {
  .ver-carrito-container {
    flex-direction: column;
  }
  .ver-carrito,
  .estado {
    margin-bottom: 10px; /* Agrega un espacio entre los elementos */
  }
}
