.images {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
}
.images img{
  margin: 0 10px;
}

.banner img {
  max-width: 60px;

}

.torneo {
  display: flex;
  margin: 0 auto;
}

.rival {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 25px;
  font-size: 0.9rem;
  font-weight: bold;
}

.alianza-vs {
  display: flex;
  align-items: center;
  flex: 1;
}

.alianza-vs img , .alianza-vs span {
  flex: 1;
}

.alianza-vs span {
  text-align: right;
}

.images {
  display: flex;
  align-items: center;
}

.images {
  content: '';
  border-left: 5px solid rgb(18 34 67); /* Cambia el color y el estilo de la línea según lo necesites */
  border-right: 5px solid rgb(121 2 3); /* Cambia el color y el estilo de la línea según lo necesites */
  height: 80px; /* Ajusta la altura según lo necesites */
}

.jornada {
  font-size: 12px;
  text-align: center;
}

.fecha {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;

}