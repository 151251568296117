/* RegistroPedidos.css */


.registro-pedidos-heading {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .tabla-registro-de-pedidos {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  .popup {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #000000bb;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 999;
    top: 0;
    left: 0;
  }
  
  .tabla-registro-de-pedidos tr {
    transition: background-color 0.3s;
  }
  
  .tabla-registro-de-pedidos tr:hover {
    background-color: #f5f5f5;
  }
  
  .tabla-registro-de-pedidos .selected {
    background-color: #cce5ff;
  }
  
  .registro-pedidos-button {
    background-color: #007bff;
    color: #fff;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }

  
  
  
  .registro-pedidos-button:hover {
    background-color: #0056b3;
  }
  
  
  .detalles-pedido-overlay h3 {
    color: #dc3545;
    font-size: 2rem;
    transition: color 0.3s ease;
  }
  
  .detalles-pedido-overlay ul {
    list-style: none;
    padding: 0;
  }
  
  .detalles-pedido-overlay a {
    color: #17a2b8;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .detalles-pedido-overlay a:hover {
    color: #138496;
  }

  

  
  .detalles-pedido-overlay th {
    background-color: #dc3545;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
  }
  
  .detalles-pedido-overlay th:hover {
    background-color: #c82333;
  }
  
  .detalles-pedido-overlay td {
    font-size: 1.2rem;
  }
  
  /* Estilo para el botón de WhatsApp */
  .detalles-pedido-overlay a.btn-whatsapp {
    display: inline-block;
    padding: 12px 20px;
    font-size: 1.8rem;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-align: center;
    text-decoration: none;
    margin-top: 15px;
  }
  
  
  .detalles-pedido-overlay a.btn-whatsapp:hover {
    background-color: #218838;
  }
  
  /* Estilo para el select */
  .detalles-pedido-overlay select {
    padding: 10px;
    font-size: 1.3rem;
    border: 1px solid #495057;
    border-radius: 5px;
    background-color: #343a40;
    color: #fff;
    outline: none;
    transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  }
  
  .detalles-pedido-overlay select:hover,
  .detalles-pedido-overlay select:focus {
    border-color: #17a2b8;
    background-color: #495057;
    color: #fff;
  }
  
  .detalles-pedido-overlay select option {
    background-color: #343a40;
    color: #fff;
  }
  
  .detalles-pedido-overlay select option:hover {
    background-color: #495057;
    color: #fff;
  }
  
  .registro-pedidos-heading {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .tabla-registro-de-pedidos {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .tabla-registro-de-pedidos th, .tabla-registro-de-pedidos td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }

  .tabla-registro-de-pedidos td {
    color: #292b2c;
  }

  .registro-pedidos-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
  }
  

  .id-number {
    background-color: #ffffff;
    padding: 10px 0;
    border-radius: 40px;
    width: 50%;
    margin: 0 auto;
    border-top: 2px solid #838383a3;
    margin: 8px auto;
    border-bottom: 2px solid #838383a3;
  }

  .id, .number {
    padding: 10px;
    border-radius: 40px;
  }

  .id {
    border-right: 2px solid #838383a3;
  }

  .estado-de-pago-title {
    color: #000000;
    font-size: 1.3rem;
  }

  .details-card-table 
  {
    width: 100%;
    margin: 0 auto;
  }
  .clock-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #3498db;
  }
  
  .clock {
    font-family: 'Roboto', sans-serif;
    background-color: #fff;
    color: #2c3e50;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .clock .date {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .clock .time {
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-bottom: 10px;
  }
  
  .clock .day {
    font-size: 24px;
    text-transform: uppercase;
  }
  
  .clock .icon {
    font-size: 48px;
    margin-bottom: 10px;
    color: #3498db;
  }
  
  /* Animación de fade-in */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .clock-container,
  .clock {
    animation: fadeIn 1s ease-in-out;
  }
  
  /* Estilos para dispositivos móviles */
  @media screen and (max-width: 600px) {
    .clock {
      width: 80%;
    }
  }
  

  /* RegistroPedidos.css */

.detalles-pedido-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    
    z-index: 1000;
    overflow-y:scroll;
    padding: 15px;
  }

  

  /* Agrega esto a tu hoja de estilos CSS */
.pedido-recibido {
  background-color: #b3ffb3; /* Verde claro para el estado "Recibido" */
}

.pedido-confirmado {
  background-color: #ffffff;
}

.pedido-en-camino {
  background-color: #ffffb3;
}

.pedido-entregado {
  background-color: #c8c8c8;
}

.registro-pedidos-button.entregado {
  background-color: grey;
}

.registro-pedidos-button.en-camino {
  background-color: #ffff55;
  color: #000000;
}

.registro-pedidos-button.confirmado {
  background-color: #6161ff;
}
.excel {
  color: #218838;
}

.google-maps-icon, .waze-icon{
  margin-left: 5px;
}

/* PedidoCard.css */
.pedido-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.pedido-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pedido-card.selected {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.pedidos-container {
  padding: 50px;
  padding-top: 20px;
}

.green {
  font-weight: bold;
}

.confirmar-btn {
  font-family: "Poetsen One", sans-serif;
  background-color: #28a745;
  border-radius: 20px;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
}

.pedido-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
}

.pedido-card-header h3 {
  margin: 0;
  font-size: 18px;
}

.pedido-card-body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.pedido-card-body h4 {
  margin: 0;
  margin-bottom: 8px;
  font-size: 16px;
}

.pedido-card-body p {
  margin: 0;
  margin-bottom: 8px;
  font-size: 16px;
  width: 100vw;
  text-align: right;
}

.pedido-card-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  padding-top: 0;
}

.pedido-card-footer select {
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  outline: none;
}

.pedido-card-footer a {
  text-decoration: none;
  color: #007bff;
}

.pedido-card-footer a:hover {
  text-decoration: underline;
}

.pedido-card-footer svg {
  cursor: pointer;
}

/* RegistroPedidos.css */


/* Elimina los estilos de la tabla y tabla-registro-de-pedidos */
.tabla-registro-de-pedidos {
  display: none;
}


.detalles-pedido-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}


.detalles-card-close-btn {
  background: none;
  border: none;
  color: #888;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.product-info {
  display: flex;
  align-items: center;
}

.product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.product-details {
  display: flex;
  flex-direction: column;
}

.product-name {
  font-weight: bold;
}

.product-quantity {
  color: #888;
}

.product-checkbox {
  text-align: center;
}

.checkbox {
  transform: scale(1.5);
}

.product-price {
  font-weight: bold;
}

.no-details {
  font-style: italic;
  color: #888;
}

.detalles-extras {
  display: flex;
  justify-content: space-between;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
}

.details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.details-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.details-link {
  color: #007bff;
  text-decoration: none;
}

.details-link:hover {
  text-decoration: underline;
}

.details-list {
  list-style: none;
  padding: 0;
}

.details-item {
  margin-bottom: 10px;
}

.details-label {
  font-weight: bold;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
}

.details-table th,
.details-table td {
  padding: 10px;
  text-align: left;
}

.details-table tbody{
  border-radius: 20px;
  border: 2px solid #000;
}



.instrucciones-area {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.instrucciones-area h3 {
  padding: 5px;
  padding-bottom: 0;
}

.instrucciones-area textarea {
  padding: 5px;
}



.detalles-pedido-table td, .detalles-pedido-table th {
  border: 2px solid #000;
  padding: 10px;
}

.pedido-card-totales {
  text-align: right;
}

.pedidos-ordenados {
  background-color: #de2424;
  padding: 20px;
  color: #fff;
}

.bg-green {
  background-color: #1ca11c;
}

.bg-yellow {
  background-color: #f1c40f;
}

.bg-gray {
  background-color: #d3d3d3;
  color: #000;
}

.bg-red {
  background-color: #de2424;
}
