.rectangles-container {
  width: 100%;
  padding: 20px 0;
  padding-top: 0;
  background: #ffffff;
  bottom: 0;
}
.dashboard {
    width: 100%;height: 37vh;
}



.rectangle-flex-container {
    display: flex;
    width: 100%;
    padding: 20px;
    padding-top: 0;
    padding-bottom: 10px;
    
    
    flex-direction: row;
    align-items: center;
    align-content: center;
  }

.rectangle {
  flex: 1 0 40%; /* Ocupa el 20% del ancho */
  height: 15vh; /* Altura deseada */
  width: 50%;
  margin: 5px; /* Espacio entre los rectángulos */
  margin-bottom: 8px;
  background-color: rgba(255, 255, 255, 0.13);
  border-radius: 25px;
  padding: 15px;
}

.faIcon {
  display: flex;
  margin: 0 auto;
  font-size: 42px;
}

.icon-bars {
  background: none;

  font-size: 3rem;
}
.icon-bars a {
  background: none;
  font-size: 5rem;
}

.icon-title {
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    font-size: 1rem;
    margin-top: 15px;
}

.tienda {
    color: rgb(25, 200, 86);
}
.tienda a, .tienda .icon-title{
    color:  rgb(25, 200, 86);
    font-weight: bold;
}

.pedidos {
    color:  rgba(25, 200, 86, 0.626);
}

.pedidos a, .pedidos .icon-title{
    color:  rgb(25, 200, 86);
}

.administrar {
    color: rgb(25, 200, 86);
}

.administrar a, .administrar .icon-title{
    color:  rgb(25, 200, 86);
}


.clientes a, .clientes .icon-title{
    color:  rgb(20, 118, 54)
}


.mis-pedidos {
    color:  rgb(20, 118, 54);
}

.mis-pedidos a, .mis-pedidos .icon-title{
    color: rgb(20, 118, 54)
}

.clientes-pedidos {
    background: #ffffff;
}

.administrar-tienda {
    background: #e7e7e7;
}