/* ListaUsuarios.css */



/* Estilo para los botones */
button {
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.registrar-usuario-form {
    display: flex;
    flex-direction: column;
    text-align: center;
    border:2px solid #dc3545;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;
}
.registrar-usuario-form label {
    text-align: left;
}

.registrar-usuario-form input, .registrar-usuario-form select  {
    width: 350px;
    padding: 3px;
}
.registrar-usuario-form select  {
    margin: 8px 0;

}