/* PedidoConfirmado.css */

.pedido-detalle-container {
  margin: 0px;
}

.en-camino-text {
  color: #ecb100; /* Verde para estado "En camino" */
}

.finalizado-text {
  color: #28a745; /* Rojo para estado "Finalizado" */
}

.transaccion-pendiente-text {
  color: #ecb100; /* Amarillo para estado "Pendiente" */
}

.transaccion-pagado-text {
  color: #28a745; /* Verde para estado "Pagado" */
}

.transaccion-rechazado-text {
  color: #dc3545; /* Rojo para estado "Rechazado" */
}

/* Otros estilos según tus necesidades */

/* PedidoConfirmado.css */

.pedido-card img {
  width: 100%;
}

.pedido-detalle-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.mg-b-10 {
  margin-bottom: 20px;
}

.activo-text {
  color: #02607c; /* Azul */
}

.en-camino-text {
  color: #ffc107; /* Amarillo */
}

.finalizado-text {
  color: #28a745; /* Verde */
}

.transaccion-pendiente-text {
  color: #ffc107; /* Amarillo */
}

.transaccion-pagado-text {
  color: #28a745; /* Verde */
}

.transaccion-rechazado-text {
  color: #dc3545; /* Rojo */
}

.pedido-details,
.transaccion-details {
  padding: 10px;
  margin-bottom: 20px;
}

dl {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

dt {
  font-weight: bold;
}

/* Media query para hacerlo responsive */
@media (max-width: 955px) {
  dl {
    grid-template-columns: 1fr;
  }
}

dl {
  display: flex;
  flex-wrap: wrap;
}

dt {
  font-weight: bold;
  flex: 1 50%; /* Ocupa el 50% del ancho en dos columnas */
}

dd {
  flex: 1 48%; /* Ocupa el 50% del ancho en dos columnas */
}

/* Media query para hacerlo responsive */
@media (max-width: 955px) {
  dl {
    flex-direction: column; /* Cambia a una columna en pantallas más estrechas */
  }

  dt,
  dd {
    flex: 1 100%; /* Ocupa el 100% del ancho en una columna */
  }
}

.numero-copiado {
  color: #007bff;
}

/* PedidoConfirmado.css */

.pedido-detalle-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.pedido-titulo {
  font-size: 24px;
}

.mg-b-10 {
  margin-bottom: 20px;
}

.en-camino-text {
  color: #28a745; /* Verde */
}

.finalizado-text {
  color: #17a2b8; /* Cian */
}

.transaccion-pendiente-text {
  color: #ffc107; /* Amarillo */
}

.transaccion-pagado-text {
  color: #28a745; /* Verde */
}

.transaccion-rechazado-text {
  color: #dc3545; /* Rojo */
}

.pedido-details,
.transaccion-details {
  margin-bottom: 20px;
}

dl {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

dt {
  font-weight: bold;
}

/* Media query for tablets and smaller screens */
@media (max-width: 955px) {
  dl {
    grid-template-columns: 1fr; /* Switch to a single column layout */
  }
}

.id-pedido {
  background: #e8e8e8;
  padding: 5px 10px;
  border-radius: 20px;
  width: 100%; /* Adjust width for smaller screens */
}

.copy-container {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
}

.instruction-list {
  color: #fff;
}


.yape-image-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.678);
}

.purple-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  border-radius: 10%;
  width: 350px;
  height: 400px;
  background-color: #742284e6;
}

.copiar {
  background: none;
  color: #ffffff;
  font-size: 2rem;
  cursor: pointer;
}

.numero-copiado {
  color: rgb(255, 255, 255);
  font-size: 2rem;
  text-align: center;
}


.hidden {
  display: none;
}

.hazclickaqui {
  background-color: #025f7c51;
  font-size: 1.5rem;
  font-family: cursive;
  border: 4px solid #02607c;
  border-bottom: 4px solid #02607c;
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  color: #02607c;
  font-family: cursive;

  text-align: center;
}
