* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.content-container {
  width: 90%;
  margin: 0 auto;
    margin-top:61px;
    height: calc(100vh - 61px - 61px);
  }

  /* styles.css */
/* Estilo para la tabla */
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  .qrcode__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }
  
  .qrcode__container--parent {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .qrcode__input {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    margin-top: 20px;
  }
  
  
  .qrcode__input input {
    width: 100%;
    padding: 10px;
    font-size: 12px;
    outline: none;
    resize: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
  }
  
  .qrcode__input button, 
  .qrcode__download button {
    display: inline-block;
    padding: 7px;
    cursor: pointer;
    color: #fff;
    background-color: rgb(96, 96, 246);
    border: none;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  .qrcode__download {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .qrcode__download button {
    margin-top: 10px;
  }
  
  /* Estilo para los encabezados de columna */
  table th {
    background-color: #333;
    color: #fff;
    font-weight: bold;
    padding: 15px;
    text-align: left;
  }
  
  /* Estilo para las celdas de datos */
  table td {
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  table tr {
    max-height: 50px;
  }
  
  /* Estilo para las filas impares (alternar colores) */
  table tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  
  /* Estilo para las filas al pasar el mouse */
  table tbody tr:hover {
    background-color: #e0e0e0;
  }
  
  /* Estilo para las celdas de encabezado de columna específicas (si es necesario) */
  table th.producto-header {
    /* Estilo específico para encabezado "Producto" si es necesario */
  }
  
  /* Estilo para botones en las celdas (si es necesario) */
  table td button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  /* Estilo para botones en las celdas al pasar el mouse (si es necesario) */
  table td button:hover {
    background-color: #0056b3;
  }
  