.flex-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-container {
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 3px 30px 0;
  height: 100vh;
  z-index: 400000000;
  width: 100%;
}

@media screen and (min-width: 600px) {
  .form-container {
    width: 80%;
  }
}

.close-form-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.close-form {
  cursor: pointer;
}

.register-form {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-bottom: 5px;
  font-weight: bold;
  text-align: justify;
}

.flex-input {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
}

.form-button, .logout-btn {
  background-color: #122243;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  padding: 5px 10px;
}

.form-title {
  font-weight: 300;
  font-size: 1.2rem;
  color: #000000;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.form-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 5px;
  width: 100%;
}

.form-button {
  padding: 10px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 15px;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.flex-space-around {
  display: flex;
  justify-content: space-around;
}

.flex-login-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: #00000094;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  top: 0;
}

.password-input-container {
  position: relative;
}

.form-input {
  width: 100%;
  padding-right: 2.5rem; /* Espacio para el icono de ojo */
}

.password-icon {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-100%);
  cursor: pointer;
}